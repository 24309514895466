<template>
    <div>
      <v-dialog
        v-model="dialog"
        persistent
        max-width="600px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="primary"
            small
            v-bind="attrs"
            v-on="on"
          >
            Verander afstand
          </v-btn>
        </template>
        <v-card>
          <v-card-title>
            <span class="headline">Verander afstand</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form
                ref="form"
                v-model="valid"
                lazy-validation
              >
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                        label="Afstand*"
                        v-model="recording.distance"
                        type="number"
                        :rules="rules.distance"
                        required
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
            <small>*verplicht veld</small>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="dialog = false"
            >
              Sluiten
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              @click="setDistance()"
            >
              Toevoegen
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
</template>

<script>
import { GET_RECORDING_BY_ID, UPDATE_RECORDING } from '../../store/actions.type'

export default {
  data(){
    return {
      dialog: false,
      valid: "",
      recording: {
        id: this.$route.params.id,
        distance: ""
      },
      rules: {
        distance: [
          v => !!v || 'Afstand is verplicht',
          v => (v && v.length <= 5) || 'De afstand mag niet langer dan 5 karakters zijn.',
        ]
      }
    }
  },
  methods: {
    setDistance: function () {
      if(this.$refs.form.validate()) {

        this.$store.dispatch(UPDATE_RECORDING, { recording: this.recording})
        setTimeout(() => {
          this.$store.dispatch(GET_RECORDING_BY_ID, { recordingId: this.$route.params.id });
        }, 500);
        this.dialog = false
        this.$refs.form.reset()
      }
    }
  }
}
</script>
