<template>
    <v-card
    class="mx-auto"
    max-width="344"
    outlined
    >
        <v-list-item three-line>
        <v-list-item-content>
            <div class="overline mb-4">
            Afstand
            </div>
            
            <v-list-item-title class="headline mb-1">
            {{ Math.floor(recording.distance * 100) / 100 }}m
            </v-list-item-title>
            <v-list-item-subtitle>
                Ace Points:
            {{recording.points}}
            </v-list-item-subtitle>
            
            <v-list-item-subtitle>
                Ace Miles:
            {{recording.ace_miles}}
            </v-list-item-subtitle>
            <view-distance-image-modal></view-distance-image-modal>
            <set-distance-modal></set-distance-modal>
        </v-list-item-content>

        <v-icon size="60">mdi-flag-triangle</v-icon>
        </v-list-item>
    </v-card>
</template>

<script>
import SetDistanceModal from '../modals/SetDistanceModal.vue'
import ViewDistanceImageModal from '../modals/ViewDistanceImageModal.vue'

export default {
  components: { ViewDistanceImageModal, SetDistanceModal },
  data(){
    return {

    }
  },
  props: {
    recording: {
        type: Object,
        default: () => ({}),
    },
  },
}
</script>