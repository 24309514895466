<template>
    <div>
      <h1>Monitor AceChallenge Cams</h1>
      
      <div class="topbar">
        <div class="menu-items">
            <fieldset title="Scrolling stuff">
            <button class="crtl-btn" @click="activateScroll">Start Scrolling</button>
            <button class="crtl-btn" @click="stopScroll">Stop Scrolling</button>
            <button class="crtl-btn" @click="fasterScroll">Scroll Faster</button>
            <button class="crtl-btn" @click="slowerScroll">Scroll Slower</button>
            </fieldset>
            <fieldset title="Monitor stuff" style="color:black;">
            <button class="crtl-btn" style="color:black;width:50%;" @click="checkCameras">Refresh Images</button>  
            <br>  
            Set interval for refresh in minutes ->   
            <input type="number" style="width:100%;" v-model.number="intervalMinutes" id="timerIntervalSetBox" value="5" min="1" max="120" @change="startTimer(intervalMinutes)">
            </fieldset>
        </div>
      </div>
      <div id="notWorking" style="">
        <div class="asdws" v-for="(file, index) in oudatedTimes" :key="index">
            <div class="false">{{ file.filename }} // {{ file.date }} @ {{ file.time }} = OUTDATED</div>
        </div>
      </div>
      <div class="menu-toggle" @click="toggleMenu">Menu</div>
      <div class="picture-grid">
        <div class="picture" v-for="(file, index) in camDataJson" :key="index">
          <div class="imageTitle"><a :href="`https://${file.ip}`">{{ file.filename }} // {{ file.date }} @ {{ file.time }}</a></div>
          <img :src="`https://admin.theacechallenge.com/camview/${file.url}?cache=${Math.random()}?`"  />
        </div>
      </div>
    </div>
  </template>
  <script>
  export default {
    data() {
      return {
        camitems: [
          { id: 1, name: 'De hoge dijk - Afslag.jpg' , ip: '10' },
          { id: 2, name: 'De hoge dijk - Vlag.jpg', ip: '11'  },
          { id: 3, name: 'Delfland - Afslag.jpg', ip: '12'  },
          { id: 4, name: 'Delfland - Vlag.jpg', ip: '13'  },
          { id: 5, name: 'Herkenbosch - Afslag.jpg', ip: '18'  },
          { id: 6, name: 'Herkenbosch - Vlag.jpg', ip: '19'  },
          { id: 7, name: 'Tilburg - Afslag.jpg', ip: '14'  },
          { id: 8, name: 'Tilburg - Vlag.jpg', ip: '15'  },
          { id: 9, name: 'Westwoud - Afslag.jpg', ip: '16'  },
          { id: 10, name: 'Westwoud - Vlag.jpg', ip: '17' },
        ],
        interval : null,
        camDataJson : [],
      html: "",
        cameraFiles: [],
      speed: 1, // global variable for speed
      isScrolling: true, // global variable for stopping scroll function
      intervalIds: [],
      intervalMinutes: 5,
      refreshTime: 0,
      oudatedTimes:[],
      };
    },
  
    mounted() {
        this.initPage();
        
        this.startTimer(5);
    },
    methods: {
        async initPage() {
          console.log(this.matchList);
            document.getElementById("timerIntervalSetBox").value = 5;
            await this.refreshCamera();
            this.startTimer(5);
            this.isScrolling = true;                
            const timeout = setTimeout(() => {
              this.activateScroll();
              // your function here
            }, 500);

            // to cancel the timeout
            clearTimeout(timeout);
        },
        async refreshCamera(){
            this.oudatedTimes = [];
            const beBeforeThisTime= Date.now() - 50000;
            if(this.refreshTime < beBeforeThisTime){ //this prevents the script from requesting more than once a minute if its glitched somehow
                //const response = await fetch('http://localhost/goofyrequest.php');
                this.refreshTime = Date.now();
                this.camDataJson = [];
                const response = await fetch('https://spinnovtest.daanj.eu/fff/');
                const html = await response.text();
                const parser = new DOMParser();
                const doc = parser.parseFromString(html, 'text/html');
                const text = doc.documentElement.textContent;
                this.camDataJson = JSON.parse(text);    
                
                Object.values(this.camDataJson).forEach(item => {
                    const url = encodeURI(item.filename);
                    this.$set(item, 'url', url);
                    if(this.isTimeOutdated(item.time) == true){
                        this.oudatedTimes.push(item);
                        
                    }
                //console.log(item.url);
                });
            console.log(this.updateCamDataJson());
            this.$forceUpdate();
                console.log("cams refreshed:  " + Date.now("hh:mm:ss"));
            }
            else{
                console.log("cams NOT refreshed:  " + Date.now("hh:mm:ss"));
            }
            if (this.isScrolling == true){
                this.scrollDown();	
            }
        },
        updateCamDataJson() {
          if (Array.isArray(this.camDataJson)) {
            this.camDataJson = this.camDataJson.map((data) => {
              const matchingItem = this.camitems.find((item) => data.filename === item.name);
              if (matchingItem) {
                data.ip = "192.168."  + matchingItem.ip +".123";
              }
              return data;
            });
          } else if (typeof this.camDataJson === 'object') {
            const valuesArray = Object.values(this.camDataJson);
            const updatedValuesArray = valuesArray.map((data) => {
              const matchingItem = this.camitems.find((item) => data.filename === item.name);
              if (matchingItem) {
                data.ip = "192.168."  + matchingItem.ip +".123";
              }
              return data;
            });
            this.camDataJson = Object.assign({}, ...updatedValuesArray.map(item => ({[item.filename]: item})));
            console.log(this.camDataJson);
          }
        },
        toggleMenu() {
            //const centermenu = document.querySelector(".centermenu");
            const menuItems = document.querySelector(".menu-items");
            //const menuBar = document.querySelector("topbar");
            if (menuItems.style.maxHeight > "0px") {
                menuItems.style.maxHeight = "0px";
                menuItems.style.width = "0px";
                //centermenu.style.height = "50px";
            } else {
                menuItems.style.maxHeight = "200px";
                menuItems.style.width = "50%";
            }
        },
        async checkCameras(){
            this.refreshTime = 0;
            await this.refreshCamera();
        },  
        isTimeOutdated(time) {  
            const inputDate = new Date();
  
            const [hour, minute] = time.split(':');
            inputDate.setHours(hour, minute, 0, 0);
            const now = new Date();
            const rounded = new Date(Math.floor(now.getTime() / (10 * 60 * 1000)) * 10 * 60 * 1000);
            rounded.setHours(rounded.getHours() - 2);
            console.log(inputDate + " < " + rounded + " = " + (inputDate < rounded));
            return inputDate < rounded;
        },
        //scrolling functions
        fasterScroll() {
            this.speed += 1;
        },
        slowerScroll() {
            if(this.speed > 1){
                this.speed -=1;
            }
            else if(this.speed > 0){

              this.speed -=0.1;
            }
            else{
                this.stopScroll();
            }
        },        
        activateScroll() {
            this.isScrolling = true;
            this.scrollDown();
          },
        scrollDown() {
            let scrollInterval = setInterval(() => {
            if(this.isScrolling == true){
              window.scrollBy(0, this.speed);
            }
              if (window.innerHeight + window.pageYOffset >= document.body.offsetHeight) {
                clearInterval(scrollInterval);
                this.scrollUp();
              }
            }, 10);
        },
        scrollUp() {
            let scrollInterval = setInterval(() => {
            if(this.isScrolling == true){
                window.scrollBy(0, -this.speed);
            }
              if (window.pageYOffset === 0) {
                clearInterval(scrollInterval);
                this.scrollDown();
              }
            }, 10);
        },
        stopScroll() {
            this.isScrolling = false;
        },
        //scrollstuff end
        //page refreshh stuff

        async executeMethod() {
            // Your method to be executed goes here
            await this.refreshCamera();                
            const timeout = setTimeout(() => {
              this.activateScroll();
              console.log("scrolling activated");
              // your function here
            }, 1500);

            // to cancel the timeout
            clearTimeout(timeout);
            //console.log('Freshed! ' + Date.now("hh:mm:ss"))
        },
        startTimer(interval) {
            this.intervalInMinutes = document.getElementById("timerIntervalSetBox").value;
            if(interval <1){
                
                this.intervalInMinutes = 5;
            }
            else{
                this.intervalInMinutes = interval;
            }
            console.log("interval set to: " + this.intervalInMinute);
                localStorage.setItem('refreshVal', 5);
            clearInterval(this.timerId);
            this.timerId = setInterval(() => {
                this.executeMethod(); 
                const timeout = setTimeout(() => {
                  this.activateScroll();
                  // your function here
                }, 500);

                // to cancel the timeout
                clearTimeout(timeout);
            }, this.intervalInMinutes * 60000);
        },
        stopTimer() {
            clearInterval(this.timerId);
        }
        },
        beforeDestroy() {
            this.stopTimer();
        },
    };


  
    
  </script>
  <style>
  body {
    margin: 0;
    padding: 0;
    background-color: white;
    color: black;
  }
#notWorking{
    margin:auto;
    z-index: 1092384;
    display: block;
    position: fixed;
    top: 50;
    left: 42,5%;
    width:auto;
    height: auto;
    background-color:red; color:black ;
}
  .picture-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }

  .picture {
    width: calc(50% - 20px);
    margin: 10px;
    position: relative;
  }

  .picture img {
    width: 100%;
    height: auto;
  }

  .imageTitle {
  font-size: 3vw;
  
    position: absolute;
    display: block;
    float: right;
    width: auto;
    z-index: 1;
    margin:auto;
   /* margin-left: 100px;
    margin-top: 5px;
   */ color:white;
    background-color: black;
    text-align: start;
    font-size: 19px;
  }
/* Minimum font size */
@media screen and (min-width: 320px) {
  .imageTitle {
    font-size: 16px;
  }
}

/* Maximum font size */
@media screen and (max-width: 1200px) {
  .imageTitle {
    font-size: 11px;
  }
}

  #scrollbtn {
    width: 100%;
    height: 100%;
  }
  .crtl-btn{
    width: 50%;
    height: 20px;
    margin: 5px;
    background-color: honeydew;
  }
  #topbar {
    height: 50px;
    width: 100%;
    border: 1px solid black;
    display: flex;
    justify-content: center;
    align-items: stretch;
    position: fixed;
    top: 0;
    left: 0;
  }

  .centermenu {
    width: 100%;
    height: 50px;
    display: flex;
    position: fixed;
    justify-content: center;
    align-items: center;
    z-index: 100;
  }

  .menu-toggle {
    background: gray;
    display: block;
    position: fixed;
    top: 70px;
    left: calc(50% - 50px);
    margin:auto;
    width: 100px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    cursor: pointer;
    z-index: 100;
  }

  #offline-cam {
    position: fixed;
    display: block;
    margin: auto;
    height: auto;
    width: auto;
    top: 75px;
    left: 50%;
    background-color: indianred;
  }

  .menu-container {
    position: fixed;
    display: flex;
  }

  .menu-items {
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    left: 25%;
    width: 100%;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s ease-out;
    background-color: #444;
    z-index: 1000;
  }

  fieldset {
    border: 0;
  }

  .menu-items a {
    display: block;
    padding: 10px;
    color: black;
    text-decoration: none;
  }

  .menu-items a:last-child {
    border-bottom: none;
  }
  </style>