export { FETCH_LEADERBOARD_DATA } from '../store/actions.type';


const state = {
  players: []
};

const mutations = {
  setPlayers(state, players) {
    state.players = players;
  }
};

const actions = {
    fetchleaderboard() {
    // Make the API request using the $http instance
    this.$http.post('/api/ace-cup/leaderboard')
      .then(response => {
        // Handle the successful response
        console.log('Group created successfully!', response.data);
        this.cancelGroup();
        // Reload the page to update the groups
        location.reload();
      })
      .catch(error => {
        // Handle the error response
        console.error('Error creating group:', error);
      });
    }
};

const getters = {
  getAllPlayers(state) {
    return state.players;
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
