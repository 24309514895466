import axios from 'axios'
import {
    CREATE_HOLE,
    GET_ALL_HOLES,
    GET_HOLES_BY_COURT,
    GET_HOLE_BY_ID,
    UPDATE_HOLE,
    SET_DEFAULT_HOLE_IMAGE, RECORDING_TEST,
} from "./actions.type";
import {
    SET_ALL_HOLES,
    SET_HOLES_BY_COURT,
    SET_HOLE_BY_ID
} from "./mutations.type";

const state = {
    holeErrors: [],
    allHoles: {},
    holesByCourt: {},
    holeById: {}
};

const getters = {
    getAllHoles: state => state.allHoles,
    getHolesByCourt: state => state.holesByCourt,
    getHoleById: state => state.holeById
};

const actions = {
    [GET_ALL_HOLES](context, {page}) {
        axios({url: `/api/holes?page=${page}`, method: 'GET'})
            .then(resp => {
                context.commit(SET_ALL_HOLES, resp.data);
            })
            .catch(err => {
                console.log(err)
            })
    },
    [GET_HOLES_BY_COURT](context, {courtId, page}) {
        axios({url: `/api/courts/${courtId}/holes?page=${page}`, method: 'GET'})
            .then(resp => {
                context.commit(SET_HOLES_BY_COURT, resp.data);
            })
            .catch(err => {
                console.log(err)
            })
    },
    [GET_HOLE_BY_ID](context, {holeId}) {
        axios({url: `/api/holes/${holeId}`, method: 'GET'})
            .then(resp => {
                context.commit(SET_HOLE_BY_ID, resp.data.data);
            })
            .catch(err => {
                console.log(err)
            })
    },
    [CREATE_HOLE](context, {hole, courtId}) {
        axios({url: `/api/courts/${courtId}/holes`, method: 'POST', data: hole})
            .then({
                context
            })
            .catch(err => {
                console.log(err)
            })
    },
    [UPDATE_HOLE](context, payload) {
        console.log(payload)
        axios({url: `/api/holes/${payload.holeId}`, method: 'PATCH', data: payload.data})
            .then({
                context
            })
            .catch(err => {
                console.log(err)
            })
    },
    [SET_DEFAULT_HOLE_IMAGE](context, {holeId}) {
        axios({url: `/api/holes/${holeId}/setdefault`, method: 'POST'})
            .then({})
            .catch(err => {
                console.log(err)
            })
    },
    [RECORDING_TEST](context, {uuid}) {
        axios({url: `/api/holes/${uuid}/recordings/check`, method: 'POST'})
            .then(() => {

                axios({
                    url: `/api/holes/${uuid}/recordings/start`, method: 'POST', data: {
                        "play_for_money": 1,
                        "play_for_free": 0
                    }
                })

            })
            .catch(err => {
                console.log(err)
            })
    }

};

const mutations = {
    [SET_ALL_HOLES](state, holes) {
        state.allHoles = holes

    },
    [SET_HOLES_BY_COURT](state, holes) {
        state.holesByCourt = holes
    },
    [SET_HOLE_BY_ID](state, hole) {
        console.log(hole)
        state.holeById = hole
    },
};

export default {
    state,
    actions,
    mutations,
    getters
};
