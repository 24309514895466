<template>
  <v-container>
    <v-row>
      <v-col v-for="(item) in cameras" :key="item.id">
        <camera :camera="item"></camera>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <h1>Informatie</h1>
        <v-card class="mx-auto" tile>

          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>Naam</v-list-item-title>
              <v-list-item-subtitle>{{ hole.name }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item two-line>
            <v-list-item-content>
              <v-img style="max-width: 200px;"
                width="10"
                :src="qr"
              ></v-img>
            </v-list-item-content>
          </v-list-item>
        </v-card>

        <h1 class="mb-3" style="margin-top: 3rem;">Recording Test</h1>
        <div class="">
          <v-btn @click="testRecording()" small color="primary">test</v-btn>
        </div>

        <h1 class="mb-3" style="margin-top: 3rem;">Parameters</h1>
        <update-hole-parameters-modal></update-hole-parameters-modal>
      </v-col>
      <v-col>
        <h1>Opnames</h1>
        <recordings-by-hole></recordings-by-hole>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Camera from '../components/Camera.vue';
import RecordingsByHole from '../components/RecordingsByHole.vue';
import {GET_CAMERAS_BY_HOLE, GET_HOLE_BY_ID, RECORDING_TEST} from '../store/actions.type';
import UpdateHoleParametersModal from "../components/modals/UpdateHoleParametersModal";

export default {
  components: {RecordingsByHole, Camera, UpdateHoleParametersModal},
  name: 'Hole',
  data() {
    return {}
  },
  created: function () {
    this.$store.dispatch(GET_HOLE_BY_ID, {holeId: this.$route.params.id})
    this.$store.dispatch(GET_CAMERAS_BY_HOLE, {holeId: this.$route.params.id})
  },
  computed: {
    hole() {
      return this.$store.getters.getHoleById;
    },
    cameras() {
      return this.$store.getters.getCamerasByHole;
    },
    qr() {
      return `https://api.qrserver.com/v1/create-qr-code/?size=100x100&data=https://theacechallenge.com/${this.hole.uuid}`
    }
  },
  methods:{

    async testRecording() {
      await this.$store.dispatch(RECORDING_TEST, {uuid: this.hole.uuid})
    }
  }
}
</script>

<style scoped>

</style>
