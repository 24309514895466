import Vue from 'vue'
import Vuex from 'vuex'
import auth from './auth.module'
import users from './users.module'
import groups from './groups.module'
import coupons from './coupons.module'
import courts from './courts.module'
import holes from './holes.module'
import recordings from './recordings.module'
import cameras from './cameras.module'
import transactions from './transactions.module'
import recordingsComments from './recordingsComments.module'
import companyCouponPools from './companyCouponPools.module'
import companyCoupons from './companyCoupons.module'
import leaderboard from './leaderboard.module';
// import example from './module-example'

Vue.use(Vuex)

/*
 * If not building with SSR mode, you can
 * directly export the Store instantiation;
 *
 * The function below can be async too; either use
 * async/await or return a Promise which resolves
 * with the Store instance.
 */

export default function (/* { ssrContext } */) {
  const Store = new Vuex.Store({
    modules: {
      leaderboard,
        auth,
        courts,
        holes,
        recordings,
        cameras,
        users,
        groups,
        coupons,
        transactions,
        recordingsComments,
        companyCouponPools,
        companyCoupons
    },

    // enable strict mode (adds overhead!)
    // for dev mode only
    strict: process.env.DEV
  })

  return Store
}
