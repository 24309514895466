<template>
    <div>
      <v-dialog v-model="dialogVisible" max-width="500px">
        <template v-slot:activator="{ on }">
          <v-btn  small color="primary" dark v-on="on">Create Group</v-btn>
        </template>
        <v-card>
          <v-card-title>
            <span class="headline">Create Group</span>
          </v-card-title>
          <v-card-text>
            <v-text-field v-model="groupName" label="Group Name"></v-text-field>
            <input type="file" @change="handleImageChange" />
          </v-card-text>
          <v-card-actions>
            <v-btn color="primary" text @click="saveGroup">Save</v-btn>
            <v-btn color="primary" text @click="cancelGroup">Cancel</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </template>
  
  <script>
  
    import { CREATE_GROUP } from '../../store/actions.type'

  export default {
    data() {
      return {
        dialogVisible: false,
        groupName: '',
        groupImage: ''
      };
    },
    methods: {
    createGroup: function () {
      if(this.$refs.form.validate()) {
        this.$store.dispatch(CREATE_GROUP, { group: this.group })
        this.dialog = false
        this.$refs.form.reset()
      }
    },
      showModal() {
        this.dialogVisible = true;
      },
      handleImageChange(event) {
        this.groupImage = event.target.files[0];
      },
      saveGroup() {
        // Perform saving logic here
        console.log('Group Name:', this.groupName);
        console.log('Group Image:', this.groupImage);
        
      // Create a FormData object to send the data as multipart form data
      const formData = new FormData();
      formData.append('group_name', this.groupName);
      formData.append('default_video_image', this.groupImage);

      // Make the API request using the $http instance
      this.$http.post('/api/groups/', formData)
        .then(response => {
          // Handle the successful response
          console.log('Group created successfully!', response.data);
          this.cancelGroup();
          // Reload the page to update the groups
          location.reload();
        })
        .catch(error => {
          // Handle the error response
          console.error('Error creating group:', error);
        });
    
      },
      cancelGroup() {
        this.dialogVisible = false;
        this.groupName = '';
        this.groupImage = null;
      }
    }
};
  </script>
  