<template>
    <v-card
    class="mx-auto"
    max-width="344"
    outlined
    v-if="user_data"
    >
        <v-list-item three-line>
            <v-list-item-content>
                <div class="overline mb-4">
                USER INFO
                </div>
                <v-list-item-content>
                    <v-list-item-title class="headline">{{ user_data.username }} ({{ user_data.first_name }} {{ user_data.last_name }})</v-list-item-title>
                    <v-list-item-subtitle>{{ user_data.email }}</v-list-item-subtitle>
                    <v-list-item-subtitle>{{ user_data.phone_number }}</v-list-item-subtitle>
                    <v-list-item-subtitle>Balance: {{ user_data.money_balance }}</v-list-item-subtitle>
                    <v-list-item-subtitle>ACE MILES: {{ user_data.ace_miles_balance }}</v-list-item-subtitle>
                    <v-list-item-subtitle>ID: {{ recording.uuid }}</v-list-item-subtitle>
                    <v-list-item-subtitle>Time: {{ new Date(recording.created_at).toLocaleString() }}</v-list-item-subtitle>
                    <v-btn @click="goToUser(recording.user_id)">Go to User</v-btn>
    
                </v-list-item-content>

            </v-list-item-content>
        </v-list-item>
    </v-card>
</template>

<script>
import axios from 'axios';

export default {
  components: {},
  data(){
    return {
        user_data: null
    }
  },
  methods:{    
    goToUser(userId) {
      this.$router.push(`/user/${userId}`);
    },
    
    async fetchUserData(userId) {
      // if(!userId) return;
      axios.get(`/api/users/${userId}`)
        .then(response => {
          this.user_data = response.data.data;
        })
        .catch(error => {
          console.error(error);
        });
    }
  },
  props: {
    recording: {
        type: Object,
        default: () => ({}),
    },
  },
  watch: {
    'recording.user_id': {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.fetchUserData(newVal);
        }
      }
    }},
//   computed: {
//     getUserData() {
//       return this.fetchUserData(this.recording.user_id);
//     }
//   }
}
</script>