<template>
    <v-data-table
      dense
      :headers="headers"
      :items="players"
      item-key="id"
      class="elevation-1"
    ></v-data-table>
  </template>
  
  <script>
  export default {
    name: 'ViewLeaderboard',
    data() {
      return {
      headers: [
        { text: 'Username', value: 'username' },
        { text: 'Position', value: 'position' },
        { text: 'Points', value: 'points' },
        { text: 'My Position', value: 'myPosition' },
        { text: 'Already Qualified', value: 'alreadyQualified' },
        { text: 'Date', value: 'date' },
        { text: 'ID', value: 'id' },
        { text: 'Image', value: 'image' },
      ],
        players: [],
      };
    },
    created() {
      this.fetchLeaderboardData();
    },
    methods: {
      fetchLeaderboardData() {
        // Make the API request using the $http instance
        this.$http
          .get('/api/ace-cup/leaderboard')
          .then(response => {
            // Handle the successful response
            this.players = response.data; // Assuming response.data contains the player data
            //console.log('Leaderboard data fetched successfully!', response.data);
          })
          .catch(error => {
            // Handle the error response
            console.error('Error fetching leaderboard data:', error);
          });
        // Your code to fetch leaderboard data goes here
      },
    },
  };
  </script>
  
  <style scoped>
  </style>
  