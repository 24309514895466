<template>
  <v-container>
    <v-row>
      <v-col>
        <delete-recording-modal class="d-flex justify-center align-center"></delete-recording-modal>
        <video ref="video" class="d-flex justify-center align-center" width="70%" height="auto" controls>
          <source :src="recording.recording_path" type="video/mp4">
          Your browser does not support the video tag.
        </video>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <recording-info-card :recording="recording"></recording-info-card>
      </v-col>
      <v-col>
        <hole-info-card :hole="recording.hole"></hole-info-card>
      </v-col>
      <v-col>
        <distance-info-card :recording="recording"></distance-info-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <h1>Berichten</h1>
        <recording-comments></recording-comments>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DistanceInfoCard from '../components/info-cards/DistanceInfoCard.vue'
import RecordingInfoCard from '../components/info-cards/RecordingInfoCard.vue'
import DeleteRecordingModal from '../components/modals/DeleteRecordingModal.vue'
import RecordingComments from '../components/RecordingComments.vue'
import { GET_RECORDING_BY_ID } from '../store/actions.type'

export default {
  components: { DeleteRecordingModal, DistanceInfoCard, RecordingComments, RecordingInfoCard },
  name: 'Recording',
  data(){
    return {}
  },
  mounted() {
    this.fetchRecording();
  },
  watch: {
    '$route.params.id': 'fetchRecording',
    recording(newRecording, oldRecording) {
      if (newRecording.id !== oldRecording.id) {
        this.loadVideo();
      }
    }
  },
  computed: {
    recording() {
      return this.$store.getters.getRecordingById;
    }
  },
  methods: {
    fetchRecording() {
      this.$store.dispatch(GET_RECORDING_BY_ID, { recordingId: this.$route.params.id });
    },
    loadVideo() {
      const videoElement = this.$refs.video;
      if (videoElement) {
        videoElement.load();
      }
    }
  }
}
</script>

<style scoped>
#video {
  margin: auto;
}
</style>
